import LazyImage from '@fingo/lib/components/images/LazyImage';
import { FingoNavbar } from '@fingo/lib/components/layout';
import { LoggedOutNavItems, LogoNavItem } from '@fingo/lib/components/layout/navbar';
import { useDynamicFingoLogo, useGetUser, useIsLogged } from '@fingo/lib/hooks';
import React, { useMemo } from 'react';
import LoggedInDesktopNavItems from './LoggedInDesktopNavItems';

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const user = useGetUser();
  const fingoLogo = useDynamicFingoLogo();

  const navbar = useMemo(() => (
    <FingoNavbar>
      <LogoNavItem
        image={(
          <LazyImage
            imagePath="images/logos/fingo-logo.webp"
            alt="fingo logo"
            width={120}
            height={50}
            format="avif"
          />
        )}
        logo={fingoLogo}
        isLogged={isLogged}
      />
      {isLogged && user && <LoggedInDesktopNavItems />}
      {!isLogged && <LoggedOutNavItems title="Iniciar Sesión" />}
    </FingoNavbar>

  ), [isLogged, user]);
  return navbar;
};

export default DesktopNavbar;
